import { MESSAGE_TYPE } from 'shared/constants/messages';
import { applyPageFilters, sortComparator } from './helpers';
import filterQueryGenerator from 'dashboard/helper/filterQueryGenerator';
import camelcaseKeys from 'camelcase-keys';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

const getters = {
  getAllConversations: ({ allConversations, chatSortFilter: sortKey }) => {
    return allConversations.sort((a, b) => sortComparator(a, b, sortKey));
  },
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getSelectedChatAttachments: ({ selectedChatId, attachments }) => {
    return attachments[selectedChatId] || [];
  },
  getChatListFilters: ({ conversationFilters }) => conversationFilters,
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const { message_type: messageType } = message;
      if (message.private) return false;

      return [MESSAGE_TYPE.OUTGOING, MESSAGE_TYPE.INCOMING].includes(
        messageType
      );
    });

    return lastEmail;
  },
  getMineChats: (_state, _, __, rootGetters) => activeFilters => {
    const currentUserID = rootGetters.getCurrentUser?.id;

    return _state.allConversations.filter(conversation => {
      const { assignee } = conversation.meta;
      const isAssignedToMe = assignee && assignee.id === currentUserID;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isChatMine = isAssignedToMe && shouldFilter;

      return isChatMine;
    });
  },
  getAppliedConversationFiltersV2: _state => {
    // TODO: Replace existing one with V2 after migrating the filters to use camelcase
    return _state.appliedFilters.map(camelcaseKeys);
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getAppliedConversationFiltersQuery: _state => {
    const hasAppliedFilters = _state.appliedFilters.length !== 0;
    return hasAppliedFilters ? filterQueryGenerator(_state.appliedFilters) : [];
  },
  getUnAssignedChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const isUnAssigned = !conversation.meta.assignee;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return isUnAssigned && shouldFilter;
    });
  },
  getAllStatusChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return shouldFilter;
    });
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getChatSortFilter: ({ chatSortFilter }) => chatSortFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },

  getContextMenuChatId: _state => {
    return _state.contextMenuChatId;
  },
  getTextTranslate: ({ textTranslate }) => {
    return textTranslate;
  },
  getTextTranslateStatus: ({ isTextTranslatePending }) => {
    return isTextTranslatePending;
  },
  getCurrentChatTranslate: ({ translateMap, selectedChatId }) => {
    return translateMap[selectedChatId] || {};
  },
  getTextTranslateLanguage: ({ selectedChatId, textTranslateLanguageMap }) => {
    return textTranslateLanguageMap[selectedChatId] || 'en';
  },
  getConversationTranslateStatus: ({
    selectedChatId,
    translateLoadingStatusMap,
  }) => {
    return translateLoadingStatusMap[selectedChatId] || false;
  },
  getConversationTranslateDoneStatus: ({
    selectedChatId,
    translateLoadingDoneStatusMap,
  }) => {
    return translateLoadingDoneStatusMap[selectedChatId] || false;
  },
  getForumTags: ({ forumTags }) => {
    return forumTags;
  },
  getForumCategories: ({ forumCategories }) => {
    return forumCategories;
  },
  getForumModalStatus: ({ isForumModalOpened }) => {
    return isForumModalOpened;
  },
  getForumModalRequestStatus: ({ isForumModalSended }) => {
    return isForumModalSended;
  },
  getForumMessages: ({ translateMap, selectedChatId, allConversations }) => {
    const selectedChat =
      allConversations.find(({ id }) => id === selectedChatId) || {};
    const chatTranslate = translateMap[selectedChatId] || {};

    if (!Array.isArray(selectedChat.messages)) return [];

    return selectedChat.messages
      .filter(message => Boolean(message.sender))
      .map(message => {
        const messageTranslate = chatTranslate[message.id] || {};
        let messageText = message.content;

        if (
          messageTranslate.sourceLanguage &&
          messageTranslate.sourceLanguage !== 'EN'
        ) {
          messageText = messageTranslate.translatedString;
        }

        return {
          id: message.id,
          text: messageText,
          senderType: message.sender?.type === 'user' ? 'support' : 'user',
        };
      });
  },
};

export default getters;
